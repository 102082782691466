import React from "react";
import Layout from "../../components/layout/layout";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import SEO from "../../components/seo";

const lunchevent = () => {
  return (
    <>
      <div className="absolute w-full h-32 z-n10 bg-gradient-to-r from-swa-1 to-swa-5"></div>
      <Layout>
        <SEO
          title="SWA Symrise Project Rebuild - Year-end Lunch 2022"
          description="Year-end lunch event for women and children in shelter homes."
        />
        <section className="max-w-4xl px-2 mx-auto sm:w-4/5">
          <div className="relative py-16 overflow-hidden bg-white">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
              <div
                className="relative h-full mx-auto text-lg max-w-prose"
                aria-hidden="true"
              ></div>
            </div>
            <div className="relative px-4 sm:px-6 lg:px-8">
              <div className="mx-auto text-lg max-w-prose">
                <h1>
                  {/* <span className="block text-base font-semibold tracking-wide text-center text-indigo-600 uppercase">
                    Introducing
                  </span> */}
                  <span className="block mt-2 text-3xl font-extrabold leading-8 tracking-tight text-center text-swa-1 sm:text-4xl">
                    Year-end lunch event for women and children in shelter homes
                  </span>
                </h1>
                {/* <h2 className="my-3 text-center">
                  Joint project by Singapore Women Association(SWA) and <br />
                  Symrise Asia Pacific Pte Ltd
                </h2> */}
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  As part of Project Rebuild, Singapore Women's Association and{" "}
                  <a
                    href="https://www.symrise.com/"
                    target="_blank"
                    rel="noreferrer"
                    className=" text-swa-1"
                  >
                    Symrise Asia Pte Ltd
                  </a>{" "}
                  organised a year-end lunch event for women and their children
                  from the adopted home shelters - Star Shelter, AFC Temporary
                  Refuge and Casa Raudha Ltd on the 3rd December 2022.
                </p>
                <div className="w-full mx-auto my-2">
                  <img
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1670239480/SymriseLunchEvent/atpbhgnbaahyjzljbmg8.jpg"
                    alt="Year-End Lunch Event"
                    className="w-full mx-auto "
                  />
                  {/* <p className="text-sm text-left text-gray-600 ">
                      {item.caption}
                    </p> */}
                </div>
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  Over 60 women and 51 children from the shelters had a
                  sumptuous lunch prepared by Qian Xi restaurant. There were fun
                  games that both children and adults participated in and
                  enjoyed together!
                </p>
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  The kids were entertained with balloons distributed by our
                  volunteers. There was also a key-chain art painting booth
                  where the kids had fun decorating their key chains to bring
                  home.
                </p>
                <div className="w-full mx-auto my-2">
                  <img
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1670239481/SymriseLunchEvent/tzowc6wzraj9ecj0lkyd.webp
                    "
                    alt="Year-End Lunch Event"
                    className="w-full mx-auto "
                  />
                  {/* <p className="text-sm text-left text-gray-600 ">
                      {item.caption}
                    </p> */}
                </div>
                <div className="bg-white">
                  <div className="py-1 mx-auto max-w-7xl ">
                    <div className=" grid grid-cols-1 gap-0.5 md:grid-cols-3 ">
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className=""
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1670239480/SymriseLunchEvent/rfpaubjvgx0z5io6ymog.webp"
                          alt="Laravel"
                        />
                      </div>
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className=""
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1670239481/SymriseLunchEvent/kypmnmt7jtptgvjcsaid.webp"
                          alt="StaticKit"
                        />
                      </div>
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className=""
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1670239481/SymriseLunchEvent/ozjv1iepwcwg82hopho5.webp"
                          alt="Statamic"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  There was also an Art Shrinking booth where the kids had fun
                  colouring their favourite characters and shrunk them with a
                  toaster oven into a keychain to bring home.
                </p>
                <div className="bg-white">
                  <div className="py-1 mx-auto max-w-7xl ">
                    <div className=" grid grid-cols-1 gap-0.5 md:grid-cols-2 ">
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className=""
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1670239482/SymriseLunchEvent/cp0k0gt7jbitpca6wgqt.webp"
                          alt="Shrinking Art"
                        />
                      </div>
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className=""
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1670239482/SymriseLunchEvent/t6kllzerg6gp1j2eqqyx.webp"
                          alt="Shrinking Art"
                        />
                      </div>
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className=""
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1670239482/SymriseLunchEvent/trymwkuuyp2bwljumxog.webp"
                          alt="Shrinking Art"
                        />
                      </div>
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className=""
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1670239482/SymriseLunchEvent/df9yplb5umpudf10gfbz.webp"
                          alt="Shrinking Art"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  Volunteers from Symrise helped the kids with the drawings and
                  toasted their artwork into keychains:
                  <ul>
                    <li className="list-disc">Sandra Lawardy</li>
                    <li className="list-disc">May Leong</li>
                    <li className="list-disc">May Chua</li>
                    <li className="list-disc">Charlene Ooi</li>
                  </ul>
                </p>
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  The event started with a laughter yoga session conducted by
                  Sara Mei Woo, Angela Wong and Feng Fang from SWA's Laughter
                  Yoga and Wellness Club. The session gave the residents a taste
                  of laughter yoga to help them connect with each other and have
                  more fun and laughter.
                </p>
                <div className="bg-white">
                  <div className="py-1 mx-auto max-w-7xl ">
                    <div className=" grid grid-cols-1 gap-0.5 md:grid-cols-2 ">
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className=""
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1670239483/SymriseLunchEvent/sdsflhgkms7zud9cwari.webp"
                          alt="Laughter Yoga"
                        />
                      </div>
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className=""
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1670239482/SymriseLunchEvent/cfbzrz1uhlli6pxjoswt.webp"
                          alt="Laughter Yoga"
                        />
                      </div>
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className=""
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1670239483/SymriseLunchEvent/ms5nqpt2jxddpbxv50eo.webp"
                          alt="Laughter Yoga"
                        />
                      </div>
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className=""
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1670239482/SymriseLunchEvent/eswivvanwzurvfg3ngnq.webp"
                          alt="Laughter Yoga"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  Our Miss Singapore Supranational 2022, Sin Jiayi and Miss
                  Singapore International 2019, Charlotte Lucille Chia, also
                  volunteered to be the MC for this event.
                </p>
                <div className="w-full mx-auto my-2">
                  <img
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1670239478/SymriseLunchEvent/ao2jrjo0jwexxxfwjnxi.webp"
                    alt="Miss Singapore International and Miss Singapore Supranational Queens as MC"
                    className="w-full mx-auto "
                  />
                  {/* <p className="text-sm text-left text-gray-600 ">
                      {item.caption}
                    </p> */}
                </div>
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  Welcome speeches by Lee Li Hua, the Singapore Women's
                  Association president, and Nadya Karina from Symrise Asia Pte
                  Ltd.
                </p>
                <div className="bg-white">
                  <div className="py-1 mx-auto max-w-7xl ">
                    <div className=" grid grid-cols-1 gap-0.5 md:grid-cols-2 ">
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className=""
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1670239479/SymriseLunchEvent/bl4svjiayaegrwq10ecx.webp"
                          alt="Lee Li Hua, President of SWA"
                        />
                      </div>
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className=""
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1670239479/SymriseLunchEvent/mmxtisvvqj57iurunpgr.webp"
                          alt="Nadya Karina from Symrise Asia Pte Ltd"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  Lunch was then served to the residents. Menu include cuisines
                  catered for Chinese and Muslim residents.
                </p>
                <div className="bg-white">
                  <div className="py-1 mx-auto max-w-7xl ">
                    <div className=" grid grid-cols-1 gap-0.5 md:grid-cols-2 ">
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className=""
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1670239479/SymriseLunchEvent/z2lfkuv4y0mrwvsyvhzf.webp"
                          alt="Lunch"
                        />
                      </div>
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className=""
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1670239479/SymriseLunchEvent/qjgwirkl9esqyooblljh.webp"
                          alt="Lunch"
                        />
                      </div>
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className=""
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1670239479/SymriseLunchEvent/xg65gq9gsv1mzbcfnk8t.webp"
                          alt="Lunch"
                        />
                      </div>
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className=""
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1670239480/SymriseLunchEvent/dkxbr6b67eyzkqv1vhlo.webp"
                          alt="Lunch"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  Volunteer singers from the{" "}
                  <a
                    href="    https://www.facebook.com/theanbh"
                    target="_blank"
                    rel="noreferrer"
                    className="font-extrabold text-swa-1"
                  >
                    Group Station
                  </a>
                  , performed well-loved songs from various genres for the
                  residents to enjoy, throughout the lunch event.
                </p>
                <div className="w-full mx-auto my-2">
                  <img
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1670239480/SymriseLunchEvent/h8be0q1bt8ueocgqpe1x.webp"
                    alt="Volunteer Singers"
                    className="w-full mx-auto "
                  />
                  {/* <p className="text-sm text-left text-gray-600 ">
                      {item.caption}
                    </p> */}
                </div>
                <div className="w-full mx-auto my-2">
                  <img
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1670239481/SymriseLunchEvent/f4c2e4huf9zay1fihuux.webp"
                    alt="Volunteer Singers"
                    className="w-full mx-auto "
                  />
                  {/* <p className="text-sm text-left text-gray-600 ">
                      {item.caption}
                    </p> */}
                </div>
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  Song performances:
                  <ul>
                    <li className="list-disc">
                      李嬿姿 - 童年串三月里的小雨 (Benson 伴舞)
                    </li>
                    <li className="list-disc">珍珍 - 因为我爱你</li>
                    <li className="list-disc">Yumie - 下一站天后& 马来儿歌</li>
                    <li className="list-disc">Lily Wong ~ SI Jantung hati</li>
                    <li className="list-disc">
                      Jojoe & Benson 印度服装演艺 - 爱的你呀何处寻
                    </li>
                    <li className="list-disc">唐丽玲 - 感恩的心</li>
                    <li className="list-disc">
                      James Poh & Rita - Top of the world
                    </li>
                    <li className="list-disc">Group - 万水千山总是情 </li>
                    <li className="list-disc">Group - 干一杯 </li>
                  </ul>
                </p>
                <div className="bg-white">
                  <div className="py-1 mx-auto max-w-7xl ">
                    <div className=" grid grid-cols-1 gap-0.5 md:grid-cols-2 ">
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className=""
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1670239481/SymriseLunchEvent/rnxtzidzjf90plp4p0nh.webp"
                          alt="Song performance"
                        />
                      </div>
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className=""
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1670239480/SymriseLunchEvent/bqkdwcmuwgtbybpxh0ub.webp"
                          alt="Song performance"
                        />
                      </div>
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className=""
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1670239480/SymriseLunchEvent/t8x8k2s9ponfz8ycytqb.webp"
                          alt="Song performance"
                        />
                      </div>
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className=""
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1670239479/SymriseLunchEvent/oomhfikpsm0d4o7rgplz.webp"
                          alt="Song performances"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  The first game session was 'Christmas tree decoration'.
                  Residents were divided into their respective tables, and each
                  table was given a small Christmas tree and some ornaments. All
                  hands were on deck to make the best-decorated tree on that
                  day! Jing Ting and Carine Lai from Symrise and Lee Li Hua from
                  SWA were the judges who selected the winners based on
                  creativity and the best use of ornaments. Juliana Khatmin and
                  Ethan Koh from Symrise helped to facilitate the game session.
                </p>

                <div className="bg-white">
                  <div className="py-1 mx-auto max-w-7xl ">
                    <div className=" grid grid-cols-1 gap-0.5 md:grid-cols-3 ">
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className="object-cover"
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1670239478/SymriseLunchEvent/hlysq6n8pejmzsxohjpc.webp"
                          alt="Christmas Tree Decoration"
                        />
                      </div>
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className="object-cover"
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1670239478/SymriseLunchEvent/p39jsowig8go5qzjxnk3.webp"
                          alt="Christmas Tree Decoration"
                        />
                      </div>
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className="object-cover"
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1670239478/SymriseLunchEvent/ie3p0n4omawcqsbqvp9h.webp"
                          alt="Christmas Tree Decoration"
                        />
                      </div>
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className="object-cover"
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1670239478/SymriseLunchEvent/ilpd9hvopobbl9ofiitt.webp"
                          alt="Christmas Tree Decoration"
                        />
                      </div>
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className="object-cover"
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1670239478/SymriseLunchEvent/o3o7amtkjf4wtglfssyj.webp"
                          alt="Christmas Tree Decoration"
                        />
                      </div>
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className="object-cover"
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1670382690/SymriseLunchEvent/ywmsv54z34f4kk3mpckl.webp"
                          alt="Christmas Tree Decoration"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  The second game session involved all the kids from the
                  shelters. They were paired up to compete against each other
                  for the fastest or best in surprise challenges given by our
                  volunteers, Daavena Ramani and Brian Chua from Symrise. The
                  children were enthusiastic about participating in the fun
                  activities and games organised by the volunteers from Symrise
                  and SWA.
                </p>
                <div className="w-full mx-auto my-2">
                  <img
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1670239485/SymriseLunchEvent/x1oaqbxzgywqny37tkpx.webp"
                    alt="Kids enjoying the games"
                    className="w-full mx-auto "
                  />
                  {/* <p className="text-sm text-left text-gray-600 ">
                      {item.caption}
                    </p> */}
                </div>
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  During the activity session, lucky draw tickets were given out
                  by Symrise volunteers - Quek Wan Lin, Chua Yean Leng and Brian
                  Chua- for the residents to win NTUC vouchers.
                </p>
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  The event ended with all the kids receiving free goodie bags
                  of chocolates and candies.
                </p>
                <div className="w-full mx-auto my-2">
                  <img
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1670239621/SymriseLunchEvent/klh5cbgxot2thvge298m.webp"
                    alt="Kids enjoying the games"
                    className="w-full mx-auto "
                  />
                  {/* <p className="text-sm text-left text-gray-600 ">
                      {item.caption}
                    </p> */}
                </div>
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  We would like to give thanks to the following for volunteering
                  in ushering duties.
                  <ul>
                    <li className="list-disc">Rachel Ku</li>
                    <li className="list-disc">Maru Miranda</li>
                    <li className="list-disc">Peigen Yu</li>
                    <li className="list-disc">Charina Baja</li>
                    <li className="list-disc">Debbie Soh</li>
                    <li className="list-disc">Yunnie Chun</li>
                  </ul>
                </p>
                <div className="w-full mx-auto my-2">
                  <img
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1670239485/SymriseLunchEvent/ihgsvi7fe7tah21aftw6.webp"
                    alt="SWA and Symrise committee and volunteers"
                    className="w-full mx-auto "
                  />
                  <p className="text-sm text-left text-gray-600 ">
                    Organising committee members and volunteers from SWA and
                    Symrise
                  </p>
                </div>
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  Learn more about{" "}
                  <a
                    href="/how/rebuild"
                    target="_blank"
                    rel="noreferrer"
                    className="font-extrabold text-swa-1"
                  >
                    Project Rebuild
                  </a>
                </p>

                <div className="px-4 py-8 mx-auto my-12 border border-gray-300 rounded-lg max-w-7xl sm:px-6">
                  <h2 className="text-2xl font-bold text-gray-900 sm:text-4xl">
                    <span className="block">
                      Is your organisation looking to support and sponsor our
                      initiatives?
                    </span>
                    <span className="block text-swa-1">Let's discuss.</span>
                  </h2>
                  <div className="flex mt-8">
                    <div className="inline-flex rounded-md shadow">
                      <Link
                        to="/forms/contact"
                        className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-white border border-transparent rounded-md bg-swa-1 hover:bg-swa-3"
                      >
                        Contact us
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default lunchevent;
